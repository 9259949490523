
.active-background {
    padding: 10px 20px !important;
    color: #D0F6FF;
    // font-size: 18px;
    font-weight: 600;
    background: #05B7E4;
    border-radius: 4px;
  }
  
  .custom-pagination .btn-check:checked+.btn,
  .btn.active,
  .btn.show,
  .btn:first-child:active,
  :not(.btn-check)+.btn:active {
    color: #05B7E4 !important;
  }
 
  .custom-pagination {
    // position: absolute;
    // bottom: 2%;
    width: 100%;
    background: #fff;
    margin-top: 10px;
  }
  
  .custom-limit-pagination {
    position: relative;
    bottom: 5%;
    width: 80%;
    background: #fff;
  }
  
  .custom-pagination {
  
    // .custom-limit-dropdown {}
  
    .count-style {
      padding: 10px 40px 10px 10px;
      border: 1px solid #D0D5DD;
      border-radius: 7px;
    }
  }