.add-btn {
    border: none;
    border-radius: 4px;
    padding: 12px 20px;
    background: #05B7E4;
    color: #fff;
}

.account-page-btn {
    background: transparent;
    color: #505D6F;
    border-radius: 4px;
    border: 1.5px solid #05B7E4;
    transition: .6s;
    height: 55px;


    &:hover {
        background: #05B7E4 !important;
        border: 1.5px solid #05B7E4;
        transition: .6s;
    }
}

.disable-btn {
    opacity: 0.5 !important;
    cursor: not-allowed;
}

.cancel-btn {
    background-color: transparent;
    border: 1px solid #eb6b6b;
    height: 55px;
    color: #EB6B6A !important;
    height: 55px;

    &:hover {
        background-color: #eb6b6b;
        color: #fff !important;

    }
}