.search-container {
    position: relative;
    display: flex;
    width: 100%;
    overflow: hidden;
}

.select-dropdown {
    border-right: 0;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    padding: 8px;
    outline: none;
    min-height: 44px;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    border-left: 1px solid black;
}

.search-input {
    padding-right: 30px;
    padding-left: 10px;
    flex-grow: 1;
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: transparent;
    overflow: hidden;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
}

.search-input:focus {
    background-color: transparent;
    box-shadow: none;
    outline: none;
}

.search-icon-product-list {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}
