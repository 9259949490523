.top-bar-text-width {
    min-width: 117px;
}

.top-bar-count-width {
    min-width: 52px;
}

.top-bar-active-tab {
    background-color: #05B7E4;
    color: #fff !important;
    padding: 5px 10px !important;
    border-radius: 4px;

    &:hover {
        color: #505D6F !important;
    }
}

@media (min-width: 360px) and (max-width: 765px) {

    .back-icon-width {
        height: 30px;
        width: 30px;
    }

    .top-bar-text-width {
        min-width: inherit;
    }

    .top-bar-count-width {
        min-width: inherit;
    }
}