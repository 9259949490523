.new-custom-search {
    border: 1px solid #505D6F50;
    border-radius: 4px;
    // padding: 12px;
    background: #FFF;
    min-height: 55px;
    width: 100%;
    padding-left: 40px;
    outline: none;
    // overflow-wrap:break-word;
}

.search-icon {
    top: 35%;
    left: 15px;
}

.add-product-btn {
    border-radius: 4px;
    background: #05B7E4;
    color: #fff;
    padding: 8px 20px;
    right: 1%;
    top: 15%;

}

@media (max-width: 320px) {
    .add-product-btn {
        padding: 6px 8px;
    }
}

@media (min-width: 360px) and (max-width: 565px) {
    .new-custom-search {
        // min-height: 40px;
    }
}

@media (min-width:360px) and (max-width:768px){
    .add-product-btn {
        right: 2%;
    }

}