.accounting-main {
    .accounting-fixed {
        position: fixed;
        top: 5.5rem;
        width: 100%;
        background: #fff;
    }
}

@media (min-width: 360px) and (max-width: 765px) {
    .accounting-main {
        .accounting-fixed {
            // top: 4.5rem;
            top: 4rem;
        }
    }
}