.search {
    border: 1px solid #505D6F;
    border-radius: 4px;
    // padding: 12px;
    background: #FFF;
    min-height: 44px;
    width: 100%;
}

.search-icon {
    top: 22%;
    left: 15px;
}
.search-style{
    padding-left: 40px;
}