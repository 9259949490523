.inputs-label{
    font-family: 'Inter' ;

}
.input-checkbox{
    min-height: 20px;
    border-radius: 4px;
    border: 1px solid #D9D9D9;
    background: #FFF;
}

.loyalty-checkbox{
    margin: 0;
    padding:0;
    min-height: 15px ;
    width:15px;
}