.custom-tabs {
    .nav-tabs {
        border-bottom: 1px solid #33333315;
        .nav-item {
            display: flex;
            justify-content: center;
            width: 50%;
            font-weight: 600;
            
            .nav-link {
                color: #7c7c7c;
                background: transparent;
                border: 0;
                // border-bottom: 1px solid #05B7E4;
            }
        }
    }
    .nav-tabs .nav-item.show .nav-link,
    .nav-tabs .nav-link.active {
        border-bottom: 3px solid #05b7e4;
        width: 100%;
        color: #05b7e4;
    }
}
