.header-main {
    background: #fff;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.09);
    position: fixed;
    width: 100%;
    z-index: 10;

    .header-nav {
        margin: 0 30px;
        min-height: 75px;
    }

    // .active{
    //     background: #505D6F;
    //     color: #fff;
    // }
    .nav-item .style-class {
        color: #505D6F;
        padding: 5px 10px !important;
        border-radius: 4px;

        &:hover {
            background: #05B7E430;
            color: #505D6F;
        }
    }

    .active {
        background-color: #05B7E4;
        color: #fff !important;
        padding: 5px 10px !important;
        border-radius: 4px;

        &:hover {
            color: #505D6F !important;
        }
    }



    .dropdown-button-hide {
        background: transparent !important;
        border: none !important;
    }

    .header-dropdown {

        &:hover {
            background: #D0F6FF;
        }
    }
}

.empty-div {
    min-height: 85px;
}

.sales-cart-icon{
    padding:0.5rem;
}

.nav-logo-width{
    max-width: 11em;
}

.nav-profile-div {
    order: 2;
}

// Staff App Mobile Responsive View
@media (min-width: 360px) and (max-width: 565px) {
    .header-nav {
        margin: 0px !important;
        min-height: 60px !important;
        padding:4px
    }


}

@media (min-width: 360px) and (max-width: 769px) {
    .empty-div {
        min-height: 65px;
    }
    .sales-cart-icon{
        padding:0;
    }
    .nav-logo-width{
        max-width: 7em;
    }
    
    .nav-tab-order{
        order: 2;
    }

    .nav-profile-div {
        order: 1;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .empty-div {
        min-height: 65px;
    }
    .sales-cart-icon{
        padding:0;
    }
    .nav-logo-width{
        max-width: 7em;
    }
    
}

.dropdown:hover>.dropdown-menu {
    display: block;
}

.dropdown>.dropdown-toggle:active {
    /*Without this, clicking will make it sticky*/
    pointer-events: none;
}

@media screen and (orientation: landscape) and (max-width: 1024px) {
    .nav-list-scroll{
        max-height: 100vh;
        padding-bottom: 7rem;
        overflow-y: scroll;
        overflow-x: hidden;
    }
    .sales-cart-icon{
        padding:0;
    }
    .nav-logo-width{
        max-width: 7em;
    }
  }

// maxHeight:"100vh", overflow:"scroll", paddingBottom:"4rem"