.side-filter {
    position: sticky;
    border-radius: 4px;
    border: 1px solid #505D6F50;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 20px 10px;
    // min-height: 100vh;
    max-height: 70vh;
    overflow: scroll;

    label {
        cursor: pointer;
    }
}

.filter-style {
    width: 20px;
    height: 15px;
    border: 2px solid #C3CBCD;
    border-radius: 4px;
    cursor: pointer;
}

.accordion-button:not(.collapsed) {
    background: transparent !important;
    border-bottom: 1px solid #D9D9D950 !important;
}

.side-filter::-webkit-scrollbar {
    display: none;
}

.accordion-filter-body {
    max-height: 25vh;
    overflow-y: scroll;
}

.filter-close-icon{
    top: 0;
    right: 0;
    background-color:#222;
    border-radius:0px 4px 0px 8px;
    cursor: pointer;
}

@media (min-width: 360px) and (max-width: 765px) {
    .side-filter {
        position: fixed;
        // top: 15vh;
        // bottom: 5px;
        // left: 0px;
        max-height: 70vh;
        width: 65%;
        padding: 10px;
        z-index: 1024;
        overflow: hidden;
    }

    .filter-spacing{
        padding-bottom: 1.5rem;
        padding-top: 0.5rem;
    }

    .filter-items-height{
        height: 55vh;
        overflow: scroll;
    }

    .filter-scroll-stop{
        height: 73vh;
        overflow: hidden;
    }

    .filter-empty-div{
        height: 12vh;
    }
}