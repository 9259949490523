.translate {
    transform: translate(-50%, -50%);
}

.custom-style {
    // color: #fff;
    font-size: 10px;
    width: 23px;
    height: 21px;
    text-align: center;
    top:5px;
    left:97%;
}

.translate-align{
    transform: translate(-93%, 10%);
}

@media (min-width: 360px) and (max-width: 765px) {
    .translate {
        transform: translate(-50%, 8%);
    }
}

@media screen and (orientation: landscape) and (max-width: 1024px){
    .translate {
        transform: translate(-50%, 8%);
    }
}