.checkout-popup{
    border: 1px solid #505D6F16;
    border-radius: 10px;
    padding: 20px;
}

.success-icon{
    top:-12%
}

.popup-bottom-design{
    background-repeat: no-repeat;
    background-position:inherit ;
    background-size:inherit ;
    min-height: 60px;
    bottom: -8%;
    width: 100%;
}


@media  (min-width:'1024px') and (max-width:'1920px') {
    .modal.show .modal-dialog{
        transform: scale(.8);
    }
    
}