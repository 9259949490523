.card-dashboard {
    // min-height: 285px;
    min-height: 290px;
    background-color: white;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .15);
    border-radius: 0.5rem;
    margin-bottom: 0.5rem;
    /* Bootstrap border-radius large equivalent */
}

.card-body {
    padding: 1.5rem;
    /* Adjust the padding as needed */
}

.card-text {
    color: #6c757d;
    /* Bootstrap text-muted color equivalent */
    margin-bottom: 0.25rem;
    /* Half the space of a spacer */
}

.card-title {
    margin-bottom: 0.5rem;
    /* Spacer equivalent */
    font-weight: bold;
}

.icon-container {
    background-color: #6f42c1;
    /* Bootstrap purple equivalent */
    color: white;
    // padding: 0.75rem; /* Adjust the padding as needed */
    border-radius: 0.25rem;
    /* Bootstrap border-radius small equivalent */
    height: 2rem;
    width: 2rem;
    // border: 1px solid #000
}

.icon-container i {
    font-size: 1.25rem;
    /* Adjust the size as needed */
}

hr {
    margin: 0.5rem 0;
    color: #bbbbbb;
    /* Bootstrap gray-300 color equivalent */
}

@media (min-width: 360px) and (max-width: 765px) {
    .card-padding {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    .card-dashboard {
        min-height: 150px;
    }

    .card-body {
        padding: 1rem 1rem;
    }

    .card-title {
        margin-bottom: 0rem;
    }

    .line-margin{
        margin-top: 4px;
        margin-bottom: 3px;
    }
}

@media (min-width:765px) and (max-width:1024px){
    .card-body {
        padding: 1rem;
        /* Adjust the padding as needed */
    }
}