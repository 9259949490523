// Badges Section CSS Started

.badge-product-sale-pending {
    position: absolute;
    text-align: center;
    // z-index: 2;
    min-width: 100px;
    // -webkit-transform: rotate(90deg);
    // -moz-transform: rotate(90deg);
    // -ms-transform: rotate(90deg);
    // transform: rotate(90deg);
}

.badge-product-sale-pending span {
    position: relative;
    background: #e4da05;
    color: #fff;
    float: left;
    font-size: 11px;
    font-weight: 400;
    height: 28px;
    line-height: 28px;
    padding: 0 10px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.5px;
    min-width: 50px;
    border-radius: 0;
    min-height: auto;
    border: 0;
}

.badge-product-sale-pending span::before {
    border: 5px solid #e4da05;
    border-color: #e4da05 transparent transparent #e4da05;
    border-width: 9px 5px;
    position: absolute;
    right: -7px;
    top: 0;
    content: "";
    // z-index: 1;
}

.badge-product-sale-pending span::after {
    border: 5px solid #e4da05;
    border-color: transparent transparent #e4da05 #e4da05;
    border-width: 10px 5px;
    position: absolute;
    right: -7px;
    bottom: 0;
    content: "";
    // z-index: 1;
}

@media (min-width: 360px) and (max-width: 765px) {
    .badge-product-sale-pending span {
        font-size: 8px;
    }
}