.popup-main {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
    background-color: rgb(0, 0, 0, 0.7);
}

.popup-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
}

.popup-image {
    max-width: 90%;
    max-height: 90%;
}

.left-icon{
    position: fixed;
    top: 45%;
    right: 90%;
    font-size: xx-large;
}

.right-icon{
    position: fixed;
    top: 45%;
    left: 90%;
    font-size: xx-large;
}

.image-height{
    height: 70vh;
    width: 70vw;
    overflow: hidden;
}

.cross-icon{
    top: 1%;
    right: 1%;
    background-color:transparent;
    border: 1px solid #222;
    border-radius: 50%;
    cursor: pointer;
}

@media screen and (min-width: 360px) and (max-width:765px) {

    .cross-icon{
        top: 7%;
        right: 7%;
        border: none;

        .cross-icon-size{
            font-size: 20px;
        }
    }

    .image-height{
        height: 100vh;
        width: 100vw;
    }

    .slide-image-width{
        min-width: 103vw;
    }
}

@media screen and (orientation: landscape) and (max-width: 1024px) {
    .image-height{
        height: 90vh;
        width: 100vw;
    }

    .cross-icon{
        top: 9%;
        right: 7%;
        border: none;

        .cross-icon-size{
            font-size: 20px;
        }
    }

    .left-icon{
        right: 92%;
    }

    .popup-image {
        max-width: 80%;
        max-height: 80%;
    }

    .slide-image-width{
        min-width: 105vw;
    }
}
