.warranty-upload-card{
    border: 1px dashed #505D6F50;
    border-radius: 10px;
    min-height: 300px;
}

.warranty-textarea-style{
    width: 100%;
    padding: 10px;
    min-height:200px;
    border: 1px solid #D9D9D9;
    border-radius: 5px ;
}

@media (min-width: 360px) and (max-width: 765px) {
    .warranty-upload-card{
        min-height: 150px;
    }

    .warranty-textarea-style{
        min-height:100px;
    }
}