.search-btn {
  padding: 7px 30px;
  background: #05b7e4;
  border: 0;
  border-radius: 4px;
  color: #fff;
  top: 15%;
  right: 1%;
}

.checkout-card {
  border: 1px solid #505d6f50;
  border-radius: 4px;
  padding: 15px;

  .checkout-product-card {
    border: 1px solid #505d6f50;
    border-radius: 4px;
    padding: 15px;
  }

  .checkbox-style {
    width: 24px;
    height: 24px;
    border-radius: 4px !important;
  }

  .product-image {
    width: 100%;
    height: 150px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.sales-checkout-card {
  border: 1px solid #505d6f50;
  border-radius: 4px;
  padding: 15px;

  .checkout-product-card {
    border: 1px solid #505d6f50;
    border-radius: 4px;
    padding: 15px;
  }

  .checkbox-style {
    width: 24px;
    height: 24px;
    border-radius: 4px !important;
  }

  .product-image {
    width: 100%;
    height: 150px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.roundOff-inp {
  input {
    max-width: 80px;
    border: 1px solid #505d6f;
    border-radius: 4px;
    min-height: 20px !important;
    padding: 0 10px 0 10px;
  }
}

.proceed-btn {
  width: 100;
  min-height: 45px;
  border-radius: 4px;
  border: none;
  background: #05B7E4;
  /* Button Shadow 1 */
  box-shadow: 0px 4px 4px 0px #505D6F25;
}

.sales-custom-select {
  min-width: 200px;
  cursor: pointer;
}

.sales-card-badge {
  position: relative;
  // left: 47px;
  margin: 10px 0 10px -15px;
}

.other-items-btn {
  background: transparent;
  color: #505D6F;
  border-radius: 4px;
  border: 1.5px dashed #05B7E4;
  transition: 0.6s;
  height: 55px;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background: #05B7E4 !important;
    border: 1.5px dashed #05B7E4;
    transition: .6s;
    color: #fff;
  }
}

.add-customer-btn {
  border-radius: 4px;
  background: #05B7E4;
  color: #fff;
  padding: 8px 20px;
  right: 1%;
  top: 45%;
}

.sales-cart-height {
  height: 100vh;
}

.sales-card-side-container-height {
  min-height: 77vh;
  max-height: 78vh;
  overflow-y: scroll;
}

/* Custom scrollbar styles */
.sales-card-side-container-height::-webkit-scrollbar {
  width: 6px;
  height: 10px; /* Set the height for horizontal scrollbar if needed */
}

.sales-card-side-container-height::-webkit-scrollbar-thumb {
  background: #888; /* Set a visible color for the scrollbar thumb */
  border-radius: 5px; /* Optional: gives a rounded look to the scrollbar thumb */
}

.sales-card-side-container-height::-webkit-scrollbar-thumb:hover {
  background: #555; /* Change this to your preferred color for hover state */
}

.sales-card-side-container-height::-webkit-scrollbar-track {
  background: #f1f1f1; /* Set a visible color for the scrollbar track */
  border-radius: 5px; /* Optional: gives a rounded look to the scrollbar track */
}


@media screen and (min-width:360px) and (max-width:765px) {

  .other-items-btn {
    font-size: 12px;
    height: 35px;
  }

  .sales-checkout-card {
    border: none;
    border-radius: none;
    padding: 0;

    .checkout-product-card {
      padding: 12px;
    }

    .product-image {
      height: 70px;
    }
  }

  .sales-product-image {
    padding: 0.25rem;
    padding-bottom: 0;
    border: 1px solid #dee2e6;
    border-bottom: 0;
  }

  .sales-input-height {
    min-height: 41px !important;
  }

  .remove-min-height {
    input {
      // min-height: inherit !important;
      min-height: 43px !important;
    }
  }

  .sales-cart-height {
    height: inherit;
  }

  .sales-custom-select {
    // min-height: 40px !important;

    .css-13cymwt-control {
      min-height: 40px !important;
    }

    .css-t3ipsp-control {
      min-height: 40px !important;
    }
  }

  .sales-card-side-container-height {
    max-height: inherit;
    overflow-y: hidden;
  }
}