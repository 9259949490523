.footer-container{
    position:absolute;
    bottom:-1px;
    background: #fff;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.09);
    position: fixed;
    width: 100%;
    z-index: 10;

    .footer-box {
        margin: 0 30px;
        min-height: 65px;
        padding:10px 5px
    }
}

.footer-empty-div {
    min-height: 50px;
}