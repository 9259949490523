
// Badges Section CSS Started

.badge-product-sale-unavailable {
    position: absolute;
    text-align: center;
    // z-index: 2;
    min-width: 100px;
    // -webkit-transform: rotate(90deg);
    // -moz-transform: rotate(90deg);
    // -ms-transform: rotate(90deg);
    // transform: rotate(90deg);
}

.badge-product-sale-unavailable span {
    position: relative;
    background: rgb(255, 48, 48);
    color: #fff;
    float: left;
    font-size: 11px;
    font-weight: 400;
    height: 28px;
    line-height: 28px;
    padding: 0 10px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.5px;
    min-width: 50px;
    border-radius: 0;
    min-height: auto;
    border: 0;
}

.badge-product-sale-unavailable span::before {
    border: 5px solid rgb(255, 48, 48);
    border-color: rgb(255, 48, 48) transparent transparent rgb(255, 48, 48);
    border-width: 9px 5px;
    position: absolute;
    right: -7px;
    top: 0;
    content: "";
    // z-index: 1;
}

.badge-product-sale-unavailable span::after {
    border: 5px solid rgb(255, 48, 48);
    border-color: transparent transparent rgb(255, 48, 48) rgb(255, 48, 48);
    border-width: 10px 5px;
    position: absolute;
    right: -7px;
    bottom: 0;
    content: "";
    // z-index: 1;
}