.audit-product-img{
    min-height: 500px;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px ;
}
.textarea-style{
    width: 100%;
    padding: 10px;
    min-height:200px;
    border: 1px solid #D9D9D9;
    border-radius: 5px ;
}