.product-card {
    border-radius: 4px;
    border: 1px solid #505D6F50;
    background: #FFF;
    padding: 10px;
    cursor: pointer;
}

.product-img {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 15rem;
    border-radius: 4px;
}

.product-card-badge {

    .badge-product-sale {
        top: 5%;
        left: 0;
    }
}

.product-list-loading-skeleton {
    height: 200px;
}

@media (min-width:360px) and (max-width:565px) {
    .product-img {
        height: 7rem;
    }

    .product-list-loading-skeleton {
        height: 100px;
    }
}

