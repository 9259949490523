.wrapper-layout {
    display: flex;
    width: 100%;
    // height: 95vh;
}

.wrapper-content {
    display: flex;
    flex: 1;
    // margin-left: 15em;
    // margin-top: 5em;
    // overflow: hidden;
}

.wrapper-card {
    // padding: 25px;
    margin: 30px;
    width: 100%;
    // min-height: 75vh;
    // border-radius: 6px;
    // border: 1px solid #BFBFBF;
    // background: #FFF;
    overflow-x: inherit;
}

@media (min-width: 566px) and (max-width:1024px) {
    .wrapper-content {
        margin-left: 0em;
    }
}

@media (min-width: 360px) and (max-width:565px) {
    .wrapper-content {
        margin-left: 0em;
    }
    .wrapper-card{
        margin: 10px;
    }
}

@media screen and (orientation: landscape) and (max-width: 1024px) {
    .wrapper-content {
        margin-left: 0em;
    }
    .wrapper-card{
        margin: 10px;
    }
}