// .product-carousal-img {
//     height: 60vh;
//     background-position: center;
//     background-repeat: no-repeat;
//     background-size: contain;
// }

.product-detail-badge {
    position: absolute;
    top: 3%;
    width: 100%;
}
