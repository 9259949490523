.rdt_TableRow{
    background: none !important;
    border: none !important;
    font-size: 12px !important ;
    font-weight: 500;

    .rdt_TableCell div {
      white-space: normal !important
    }
  
}

.rdt_TableRow:nth-child(even) {
    background: #d0f6ff73 !important;
    // background: rgba(208, 246, 255, 0.30) !important;;
}


.rd-row-accordion {
    display: none; /* Hide the expand button */
  }

  /* Add these styles to your CSS file */
.data-table-container {
    position: relative;
    min-width: 100% !important;
    width: 100% !important;
    
  }
  .data-table-container .rdt_TableBody{
    // max-height: 350px;
    max-height: 45vh;
    min-height: 28vh;
    overflow-y: auto; 
  }
  
  .data-table-container .rdt_TableHead, .rdt_TableHeadRow {
    position: sticky;
    top: 100;
    background-color: #D0F6FF;
    z-index: 1;
  }
  ul.dropdown-menu{
    min-width: auto !important;
  }

  .rdt_TableCol_Sortable div{
    white-space: normal !important;
  }


// Reduce scroll width
.data-table-container .rdt_TableBody::-webkit-scrollbar {
  width: 8px; 
}

.data-table-container .rdt_TableBody::-webkit-scrollbar-thumb {
  background-color: #888; 
}

.data-table-container .rdt_TableBody::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
  