.inputs-label {
    font-family: 'Inter';

}

.inputs {
    min-height: 55px;
    border-radius: 4px;
    border: 1px solid #D9D9D9;
    background: #FFF;
}

.price-range-height {
    input {
        min-height: 10px !important;
    }
}

.text-area{
min-height: 150px;
}

.radio-button-style{
    height: 15px;
    width: 15px;
    accent-color:#049bc1f7;
}
