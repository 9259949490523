$color-black: black;
$color-white: #fff;
$color-blue-light: #05b7e4;

* {
    font-family: "inter";
}

#root {
    background: #fff;
    // height: 100vh;
}

.main-color {
    color: #05b7e4;
}

.secondary-color {
    color: #556874;
}

.black-color {
    color: #243641;
}

.color-black {
    color: #000;
}

.color-gray {
    color: gray;
}

.dark-gray {
    color: #505d6f;
}

.background-dark-gray {
    background-color: #505d6f;
    // color: #505D6F;
}

.w-80 {
    width: 80%;
}

.fs-10 {
    font-size: 10px;
}

.fs-12 {
    font-size: 12px;
}

.fs-11 {
    font-size: 11px;
}

.fs-14 {
    font-size: 14px;
}

.fs-16 {
    font-size: 16px;
}

.fs-18 {
    font-size: 18px;
}

.fs-20 {
    font-size: 20px;
}

.fs-22 {
    font-size: 22px;
}

.fs-24 {
    font-size: 24px;
}

.fs-26 {
    font-size: 26px;
}

.fs-30 {
    font-size: 30px;
}

.fw-400 {
    font-weight: 400;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.fw-700 {
    font-weight: 700;
}

.fw-800 {
    font-weight: 800;
}

.button-background {
    background-color: #05b7e4;
}

.color-white {
    color: rgb(255, 255, 255);
}

.color-green {
    color: #138200;
}

.color-yellow {
    color: #ffba00;
}

.color-red {
    color: #fb3f3f;
}

.background-light-green {
    background-color: #d1ffca;
}

.background-light-red {
    background-color: #ffa7a7;
}

.background-light-yellow {
    background-color: #fff6e0;
}

.error {
    border: 1px solid rgb(255, 48, 48);
}

.error-text {
    color: rgb(255, 48, 48);
    font-size: 12px;
}

.hide-scroll::-webkit-scrollbar {
    display: none;
}

.color-change:hover {
    background-color: #8fa9a08c;
}

.custom-input-height {
    input {
        min-height: 55px !important;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.id-input-design {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 10px;
}

.dashboard-card-height {
    // height: 157px;
    background: #fff;
    color: #556ee6;
    border-radius: 21px;
    box-shadow: 0px 0px 5px #00000030;
}

.login-height {
    max-height: 46rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 15px;
    border-radius: 20px 0px 0px 20px !important;
    min-height: 100%;
}

.warranty-barcode-width {
    width: 160px;
}

@media (min-width: 1081px) and (max-width: 400000px) {
    .container-fluid {
        margin: 0 auto;
        max-width: 1440px;
    }
}

.product-carousal-img {
    height: 400px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.carousel {
    .control-dots {
        background-color: #22222230 !important;
        align-items: center;
        padding-top: 5px;
        min-height: 40px;
        margin-bottom: 0;
        border-radius: 0 0 10px 10px;
    }

    .thumbs-wrapper {
        display: none;
    }
}

.mt-20 {
    margin-top: 20px;
}

.mt-25 {
    margin-top: 25px;
}


.orders-badge {
    background-color: #ffebb9;
    color: #c58e00;
    font-weight: 400;
    padding: 6px 14px;
    border-radius: 5px;
    margin-top: 14px;
}

.custom-badge {
    font-weight: 500;
    padding: 1px 10px;
    border-radius: 5px;
}

.text-nowrap {
    text-wrap: nowrap !important;
}

//  .rdt_TableCell .erkbiK div span{
//     overflow: visible !important;
// }

.custom-search {
    position: absolute;
    width: 100%;
    z-index: 1;

    li {
        padding: 1rem 0 1rem 2rem;
    }
}

.list-hover:hover {
    background-color: #deebff;
    width: 100%;
}

.form-control:focus {
    box-shadow: 0 0 0 0 rgba(13, 110, 253, 0.25);
}

.custom-black-bg {
    background: #000;
    border-radius: 0px 0px 5px 5px;
}

// ====== Hiding Scroll From Sales Cart Select Issues=======

/* Hide scrollbar for Chrome, Safari and Opera */
.wrapper-card::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.wrapper-card {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.custom-download-icon-style {
    display: flex;
    font-size: 16px;
    border: 1px solid #505d6f;
    border-radius: 50%;
    margin: 0;
    padding: 2px;
}

// ====== Ended Hiding Scroll From Sales Cart Select Issues =======

// Staff App Mobile Responsive View
@media (min-width: 360px) and (max-width: 565px) {
    .login-screen {
        width: 100% !important;
    }

    .product-carousal-img {
        height: 155px;
    }
}

.dark-gray {
    color: #505d6f;
}

.modal-content .modal {
    width: 700px;
}

.text-unselected {
    -webkit-user-select: none;
    user-select: none;
}


.product-list-scroll {
    max-height: 69vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.photo_upload {
    width: 165px;
    height: 140px;
    // margin: 1rem 2rem 1rem 0 !important;
    position: relative;

    .photo_preview {
        width: 165px;
        height: 140px;
        position: relative;
        border-radius: 4px;
        border: 1.8px solid rgba(80, 93, 111, 0.50);
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);

        .preview_image {
            width: 100%;
            height: 100%;
            position: absolute;
            background-size: cover;
            border-radius: 4px;
        }
    }

    .delete-icon {
        position: absolute;
        right: -10px;
        top: 2px;
        width: 20px;
        height: 20px;
        border-radius: 0px 4px;
        background: #D9D9D9;
        cursor: pointer;

        &:hover {
            background: #e1d4cd;

        }

    }
}

.outline-button-filter {
    text-align: center;
    border-radius: 4px;
    border: 1px solid #505D6F;
    background-color: transparent;
    padding: 10px 8px;
    color: #505D6F;
    min-height: 44px;
}


.action-button {
    background: #D0F6FF;
    border: aliceblue;
}

.action-button:hover {
    background-color: gray !important;
    border: aliceblue;
}

.action-button:active {
    background-color: gray !important;
    border: aliceblue;
}

.border-product-radius {
    border-radius: 5px;
}

.table-delete-button {
    text-align: center;
    border-radius: 4px;
    border: 1px solid #FB3F3F;
    background-color: transparent;
    padding: 7px 20px;
    color: #FB3F3F;

}

.table-edit-button {
    text-align: center;
    border-radius: 4px;
    border: 1px solid #505D6F;
    background-color: transparent;
    padding: 7px 20px;
    color: #505D6F;

}

.table-delete-button {
    text-align: center;
    border-radius: 4px;
    border: 1px solid #FB3F3F;
    background-color: transparent;
    padding: 7px 20px;
    color: #FB3F3F;

}

.table-edit-button {
    text-align: center;
    border-radius: 4px;
    border: 1px solid #505D6F;
    background-color: transparent;
    padding: 7px 20px;
    color: #505D6F;

}


.accountDetail-text-Size {
    font-size: 26px;
}

.brand-add-Button {
    width: 31px;
    height: 33px;
    border: 1px solid;
    border-radius: 26px;
    color: white;
    background: #05B7E4;
    box-shadow: 0px 5px 7px 3px #D9D9D9;
    border: none;
}

.delete-btn {
    margin-top: 25px;
    padding-top: 10px;
    // width: 7%;
    height: 55px;
    width: 55px;
    border: 1px solid #FB3F3F;
    border-radius: 5px;
    text-align: center;
    align-items: center;
}

.nav-user-icon {
    padding: 0.4rem;
    width: 48px;
    height: 48px;
    color: #505D6F;
    font-size: 22px;
}

// prescription css

.prescription-container {
    max-height: 79vh;
    overflow-x: hidden;
    overflow-y: auto;
}

.prescription-share-button {
    position: absolute;
    bottom: 13%;
    right: 11%;
    padding: 20px;
    background-color: #05b7e4;
    border-radius: 50%;
    box-shadow: 60px 31px 18px 0px rgba(0, 0, 0, 0.1);
}

.prescription-share-icon {
    font-size: 25px;
    color: #fff;
}

.prescription-download-icon {
    border: 0.5px solid;
    border-radius: 50%;
    padding: 0.5rem;
    width: 45px;
    color: #505D6F;
    font-size: 22px;
}

.login-container {
    background-color: #D0F6FF30;
    height: 100vh;
}

.login-sign-text {
    letter-spacing: 1px;
    text-decoration: underline;
}

.view-password-icon {
    cursor: pointer;
    position: absolute;
    right: 10px;
    bottom: 15px;
    font-size: 20px;
}

.login-box {
    border-radius: 21px;
    width: 75%;
}



.product-list-loader {
    position: absolute;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;

    div {
        position: fixed;
        z-index: 999;
    }
}

@media screen and (min-width: 1400px) and (max-width:2560px) {
    .product-list-scroll {
        max-height: 75vh;
    }
}

@media screen and (min-width: 2500px) {
    .product-list-scroll {
        max-height: 90vh;
    }
}

.left-border {
    border-left: 1px solid #00000050;
}

.input-container-height {
    input {
        min-height: 48px !important;
    }
}

@media (min-width: 768px) {
    .fs-md-12 {
        font-size: 12px;
    }

    .fs-md-14 {
        font-size: 16px;
    }

    .fs-md-16 {
        font-size: 16px;
    }

    .fs-md-18 {
        font-size: 18px;
    }

    .fs-md-20 {
        font-size: 20px;
    }

    .fs-md-24 {
        font-size: 24px;
    }
}

@media (min-width: 360px) and (max-width: 765px) {
    .left-border {
        border-left: none;
    }

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        background-color: rgba(34, 34, 34, 0.188);
        /* Adjust the alpha value as needed */
        z-index: 1024;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .product-list-scroll {
        max-height: 80vh;
    }

    .accountDetail-text-Size {
        font-size: 20px;
    }

    .nav-user-icon {
        padding: 0.2rem;
        width: 35px;
        height: 35px;
        font-size: 19px;
    }


    .warranty-barcode-width {
        width: 115px;
    }

    .payment-voucher-card {
        border: 1px solid #ccc;
        border-radius: 8px;
        padding: 20px;
        box-shadow: 0 3px 4px rgba(0, 0, 0, 0.1);
        padding: 0.75rem;
    }

    .warranty-invoice-width {
        min-width: 85px;
    }

    .photo_upload {
        width: 140px;
        height: 130px;

        .photo_preview {
            width: 140px;
            height: 130px;
        }
    }

    .voucher-padding-right {
        padding-right: 0;
    }

}

@media (min-width:768px) and (max-width:1024px) {
    .voucher-padding-lg {
        padding-left: 0;
        padding-right: 0;
    }
}

@media (min-width:985px) and (max-width: 1036px) {
    .login-box {
        margin-top: 18rem;
    }
}

// for landscape mobile view
@media screen and (orientation: landscape) and (max-width: 1024px) {
    .login-box {
        margin-top: 18rem;
    }

    .nav-user-icon {
        padding: 0.2rem;
        width: 35px;
        height: 35px;
        font-size: 19px;
    }

    .top-bar-width {
        width: 70vw !important;
    }

    .remove-padding-right {
        padding-right: 0;
    }

    .remove-padding-left {
        padding-left: 0;
    }
}

.glass-div {
    border: 1px solid $color-black; // Default border color
    background-color: $color-white; // Default background color
    color: $color-black; // Default text color

    &.glass-div-selected {
        border-color: $color-blue-light; // Selected border color
        background-color: $color-blue-light; // Selected background color
        color: $color-white; // Selected text color
    }
}

@media (min-width: 950px) and (max-width: 1420px) {
    .button-position {
        width: 100%;
        justify-content: flex-end !important;
    }
}

@media (max-width: 1100px) {
    .margin_top_10 {
        margin-top: 10px;
    }
}