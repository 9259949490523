.diagonal-color-div {
    width: 20px;
    height: 20px;
    position: relative;
}

.half-color {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;

}

.half-color:nth-child(1) {
    clip-path: polygon(0 0, 100% 0, 0 100%);
}

.half-color:nth-child(2) {
    clip-path: polygon(100% 0, 100% 100%, 0 100%);
}

.color-div {
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

.glass-div {
    border: 1px solid #222;
    border-radius: 4px;
    padding: 10px;
}

.active-border-color {
    border:1px solid #05b7e4;
}

@media (min-width: 360px) and (max-width: 765px) {
    .color-div {
        width: 10px;
        height: 10px;
    }
    .glass-div {
        padding: 6px;
    }
}