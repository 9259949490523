.multi_select{
    min-height: 50px !important;
    border-radius: 4px;
    background : #FFF;
    .css-13cymwt-control{
        min-height: 55px;
    }
    .css-t3ipsp-control{
        min-height: 55px;
    }
    .css-1u9des2-indicatorSeparator{
        background-color: transparent !important;
    }
}