// Badges Section CSS Started

$badge-available-color: #45ad14;
// $badge-other-available-color: #14adab;
$badge-other-available-color: #1565c0;
$badge-not-available-color: #d9ca17f5;

$badge-text-color: #fff;
$badge-text-black-color: #000000;


.badge-product-sale {
  position: absolute;
  text-align: center;
  min-width: 100px;

  span {
    position: relative;
    color: $badge-text-color;
    float: left;
    font-size: 11px;
    font-weight: 500;
    height: 28px;
    line-height: 28px;
    padding: 0 10px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    min-width: 50px;
    border-radius: 0;
    border: 0;

    &::before,
    &::after {
      content: "";
      position: absolute;
      right: -7px;
    }

    &::before {
      border: 5px solid transparent;
      border-width: 9px 5px;
      top: 0;
    }

    &::after {
      border: 5px solid transparent;
      border-width: 10px 5px;
      bottom: 0;
    }
  }

  &.available span {
    background: $badge-available-color;

    &::before {
      border-color: $badge-available-color transparent transparent
        $badge-available-color;
    }

    &::after {
      border-color: transparent transparent $badge-available-color
        $badge-available-color;
    }
  }

  &.unavailable span {
    background: $badge-not-available-color;
    color:$badge-text-black-color;
    font-weight: 600;

    &::before {
      border-color: $badge-not-available-color transparent transparent
        $badge-not-available-color;
    }

    &::after {
      border-color: transparent transparent $badge-not-available-color
        $badge-not-available-color;
    }
  }

  &.otherunavailable span {
    background: $badge-other-available-color;

    &::before {
      border-color: $badge-other-available-color transparent transparent
        $badge-other-available-color;
    }

    &::after {
      border-color: transparent transparent $badge-other-available-color
        $badge-other-available-color;
    }
  }
}

@media (min-width: 360px) and (max-width: 765px) {
  .badge-product-sale span {
    font-size: 6px;
  }
}
