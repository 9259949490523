.modal-right {
    padding-right: 0 !important;
    transition: all 60000ms ease-in-out;

    .modal-dialog {
        margin: 0 auto;
        margin-right: 0;
        margin-top: 0;
        margin-bottom: 0;
        height: 100%;
        max-width: 50%;
    }

    .modal-content {
        min-height: 100%;
        border: 0;
        border-radius: 0;
    }

    .modal-header {
        height: 100px;
    }


    .modal.fade .modal-dialog {
        transform: translate(25%, 0) !important;

    }

    .modal.show .modal-dialog {
        transform: translate(0, 0) !important;

    }
}



// =======Content Styles=======
.info-style {
    margin-top: 8px;
    border-bottom: 1px solid #505D6F15;
}

.info-text {
    padding-bottom: 7px;
    line-height: 28px;
}

.info-cover-img {
    border-radius: 4px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 115px;
    height: 115px;
}

.cover-text-bg {
    border-radius: 0px 0px 4px 4px;
    background: #22222250;
    color: #fff;
    padding: 5px;

}

.reject-btn {
    border: 1px solid #FB3F3F;
    border-radius: 4px;
    background: transparent;
    color: #FB3F3F;
    width: 50%;
}

.approve-btn {
    border: 1px solid #05B7E4;
    border-radius: 4px;
    width: 50%;
}

.add-product-custom-div {
    border: 1px solid #80808078;
    border-radius: 4px;
    width: 13rem;
}

.add-inventory-custom-div {
    border: 1px solid #80808078;
    min-height: 7rem;
}

@media (max-width: 768px) {
    .modal-right {
        .modal-dialog {
            max-width: 85%;
        }
    }

    .add-product-custom-div {
        width: 8rem;
    }
}